import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import UploadedLogo from 'components/UploadedLogo'
import { useMediaQueryContext } from '../context/media'
import SEO from '../components/seo'
import { Parallax } from 'react-scroll-parallax'
import SkewBackground from 'components/SkewBackground'
import ContactForm from 'components/contact-form'
import { theme } from '../twconfig'
import { SquaresBackgroundSvg } from './home'
import viewportMotion from '../components/viewport-motion'
import ProjectsSwiper from '../components/projects-swiper'
import { CasesDesktop as CasesDesktopBase } from '../components/home/projects'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Container from '../components/container'
import TextTransition from '../components/text-transition'
import { emptyVariants, motionTransition } from '../util'
import { motion } from 'framer-motion'
import TransitionPage from '../transition-page'

const introEnterViewportVariants = {
  visible: {
    transition: {
      staggerChildren: .33,
    }
  },
  hidden: {}
}

const enterViewportChildrenVariants = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  hidden: {
    opacity: 0,
    x: 0,
    y: 30,
  }
}

const GlossaryTermTemplate = ({ data: { glossaryTerm, allProject: { nodes: projects } } }) => {
  const breakpoints = useMediaQueryContext()

  const {
    title,
    route,
    logo,
    remark,
    meta,
  } = glossaryTerm

  const sectionParallaxY = useMemo(() => {
    return [`150px`, `0px`]
  }, [])

  return (
    <TransitionPage className="pt-12 lg:pt-20">
      <SEO pathname={route} {...meta} />

      <Container className="mt-24 mb-16 lg:mt-40">
        <viewportMotion.div variants={introEnterViewportVariants} className="lg:flex lg:flex-row lg:flex-no-wrap items-center lg:-mx-5 lg:overflow-hidden">
          <motion.header variants={enterViewportChildrenVariants} transition={motionTransition} className="lg:w-2/5 lg:px-5">
            {logo && (
              <UploadedLogo
                className="mx-auto w-24 h-24 lg:w-32 lg:h-32"
                modulePath={logo.relativePath}
              />
            )}
            <motion.h1 variants={enterViewportChildrenVariants} transition={motionTransition} className="text-3xl text-center uppercase my-6 md:my-10 md:text-4xl">
              {title}
            </motion.h1>
          </motion.header>

          <motion.div variants={enterViewportChildrenVariants} transition={motionTransition} className="lg:w-3/5 lg:px-5">
            {remark && (
              <div dangerouslySetInnerHTML={{ __html: remark.html }} />
            )}
          </motion.div>
        </viewportMotion.div>
      </Container>

      {projects.length ? (
        <Parallax
          y={sectionParallaxY}
          tagOuter="section"
          styleInner={{ transform: `translateY(${sectionParallaxY[0]})` }}
        >
          <SkewBackground
            className="flex flex-col justify-center pt-16 pb-20 lg:pt-20 lg:pb-24 xxl:pt-24 xxl:pb-32"
            bgColor={theme.colors.gray[`600`]}
          >
            <h2 className="section-title mb-8">
              <viewportMotion.span
                variants={emptyVariants}
                className="inline-block bg-gray-600 px-4"
              >
                <TextTransition
                  text="Where we use it"
                  transition={{ mass: 0.1, stiffness: 25, damping: 25 }}
                />
              </viewportMotion.span>
            </h2>
            {!breakpoints.lg ? (
              <div>
                <ProjectsSwiper items={projects} />
              </div>
            ) : (
              <div className="container">
                {/* <div className="flex flex-row flex-no-wrap">
                </div> */}
                <CasesDesktop
                  items={projects}
                  itemClassName="w-1/3 px-4 my-4"
                />
              </div>
            )}
          </SkewBackground>
        </Parallax>
      ) : null}

      {/* Contact form */}

      <aside className={`bg-white pt-24 pb-12 xl:pb-16 ${projects.length ? `-mt-20 pt-40 xl:pt-48` : ``}`}>
        <h2 className="section-title text-gray-500 mb-10">
          Say Hello World
          <small className="block text-base text-gray-400 text-brand normal-case font-light tracking-normal">
            and let's get the job done
          </small>
        </h2>

        <div className="container">
          <ContactForm
            className="md:max-w-sm mx-auto"
            name="contact"
            data-netlify="true"
            netlify-honeypot="fullname"
          />
        </div>
      </aside>
    </TransitionPage>
  )
}

const CasesDesktop = styled(CasesDesktopBase)`
  @media (min-width: ${theme.screens.lg}) {
    .case-details {
      ${tw`p-4`}
      ${tw`text-sm`}
      
      h3 {
        ${tw`text-xl leading-snug`}
      }
    }
  }

  @media (min-width: ${theme.screens.xl}) {
    .case-details {
      ${tw`p-10`}
      ${tw`text-base`}
      
      h3 {
        ${tw`text-3xl leading-snug`}
      }
    }
  }
`

export const projectQuery = graphql`
  query GlossaryTermPage($id: String!, $slug: String!) {
    glossaryTerm(id: {eq: $id}) {
      ...CMS_Meta

      title
      route
      logo {
        relativePath
      }
      remark: childMarkdownRemark {
        html
      }
    }

    allProject(
      limit: 4
      filter: { technologies: {elemMatch: {slug: {in: [$slug]}}} }
      sort: {fields: [projectYear], order: [DESC]}
    ) {
      nodes {
        ...ProjectFragment
      }
    }
  }
`

export default GlossaryTermTemplate
